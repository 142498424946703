<template>
  <div class="CommunityInfoConfigList-wrapper">
    <list
      ref="list"
      exportPermission="export"
      :searchUrl="searchUrl"
      :exportUrl="exportUrl"
      :searchParams.sync="searchParams"
      :headers="headers"
      :isMultiSelect="false"
    >
      <template #headerSlot>
        <v-button text="新增" @click="create"></v-button>
      </template>
      <template #searchSlot>
        <v-select label="第三方平台名称" v-model="searchParams.appType" :options="platfromOps" @change="onPlatfromChange"></v-select>
        <v-select label="产品类型" v-model="searchParams.appId" :options="productTypeOps"></v-select>
        <v-select2 label="所属项目" v-model="searchParams.communityId" v-bind="communityParams"></v-select2>
        <v-select label="状态" v-model="searchParams.status" :options="statusOps"></v-select>
        <v-datepicker label="创建时间段" type="rangedatetimer" :startTime.sync="searchParams.createStartTime" :endTime.sync="searchParams.createEndTime"></v-datepicker>
        <v-datepicker label="操作时间段" type="rangedatetimer" :startTime.sync="searchParams.startTime" :endTime.sync="searchParams.endTime"></v-datepicker>
      </template>
      <template #operateSlot="scope">
        <v-button v-if="scope.row.status === 0" text="开启" type="text" @click="changeStatus(scope.row)"></v-button>
        <v-button v-else text="关闭" type="text" @click="changeStatus(scope.row)"></v-button>
      </template>
    </list>
  </div>
</template>

<script>
import { communityParams } from 'common/select2Params'
import { getListURL, exportListURL, queryProductTypeURL, changeStatusURL } from './api'
import { statusMap, setStatusOps, setPlatformOps, platformMap } from './map'

export default {
  name: 'CommunityInfoConfigList',
  data () {
    return {
      searchUrl: getListURL,
      exportUrl: exportListURL,
      platfromOps: setPlatformOps(1),
      statusOps: setStatusOps(1),
      productTypeOps: [],
      communityParams,
      searchParams: {
        appId: undefined,
        status: undefined,
        createStartTime: '',
        createEndTime: '',
        startTime: '',
        endTime: '',
        appType: undefined,
        productType: undefined
      },
      headers: [
        {
          prop: 'platform',
          label: '第三方平台名称',
          formatter: row => platformMap[row.appType]
        },
        {
          prop: 'appName',
          label: '产品类型'
        },
        {
          prop: 'communityName',
          label: '所属项目'
        },
        {
          prop: 'statusText',
          label: '状态',
          formatter: row => statusMap[row.status]
        },
        {
          prop: 'intime',
          label: '创建时间'
        },
        {
          prop: 'updateUserName',
          label: '操作人'
        },
        {
          prop: 'updateTime',
          label: '操作时间'
        }
      ],
      productTypes: []
    }
  },
  created () {
    this.queryProductType()
  },
  methods: {
    create () {
      this.$router.push({
        name: 'communityInfoConfigForm'
      })
    },
    async changeStatus (row) {
      let isConfirm = await this.$confirm('关闭之后，该项目将不出现在第三方平台中，请确认是否操作?')
      if (isConfirm) {
        await this.$axios.post(changeStatusURL, this.$qs.stringify({ id: row.id, status: row.status === 0 ? 1 : 0, dataObject: `${row.appName}-${row.communityName}` }))
        this.$refs.list.searchData()
      }
    },
    async queryProductType () {
      let { data, status } = await this.$axios.get(queryProductTypeURL)
      if (status === 100) {
        this.productTypes = data
        this.productTypeOps = data.map(item => ({ text: item.name, value: item.appId }))
        this.productTypeOps.unshift({
          text: '全部',
          value: undefined
        })
      }
    },
    onPlatfromChange (val) {
      if (val) {
        this.productTypeOps = this.productTypes.filter(item => item.appType === val).map(item => ({ text: item.name, value: item.appId }))
      } else {
        this.productTypeOps = this.productTypes.map(item => ({ text: item.name, value: item.appId }))
      }
      this.productTypeOps.unshift({
        text: '全部',
        value: undefined
      })
    }
  }
}
</script>
